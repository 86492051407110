import React, { Component } from "react";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";

import FormFactory from "../../../components/FormFactory";
import BaseFormPage from "../../../base/BaseFormPage";
import { getObjectUseCase, upsertUseCase } from "../../../usecases/object";
import LiquidateFormPresenter from "./LiquidateFormPresenter";
import schema from "./schemaLiquidate.json";
import { dialog } from "nq-component";
import InputFactory2 from "../../../components/InputFactory2";
import InputFactory from "../../../components/InputFactory";

class LiquidateFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      reimbursement: 0,
      totalItems: 0,
      liquiAmount: [],
      liquiAmounts: {},
      newDiff: {},
      initial: 0,
      count: false,
    };

    this.presenter = new LiquidateFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  getCollectionName() {
    return "budget_request";
  }

  onSetReimbursement(e) {
    this.setState({ reimbursement: e.target.value });
  }

  onChangeObject(object) {
    let totalAmount = 0;
    let liquiAmounts = { ...this.state.liquiAmounts };
    if (object.items_list && object.items_list.length > 0) {
      object.items_list.forEach((item) => {
        // Check if the item has the 'amounts' property
        if (item.amounts) {
          // Remove commas from the amounts string and then parse it to float
          const amount = parseFloat(item.amounts.replace(/,/g, ""));
          if (!isNaN(amount) && amount > 0) {
            totalAmount += amount;

            liquiAmounts.amounts = item.amounts; // Store amount associated with the item
          }
        }
      });
    }

    // Calculate the difference between returnAmounts and totalAmount
    const difference = Math.abs(object.totalAmount - totalAmount);
    if (totalAmount > 0 || totalAmount) {
      // Save the difference in another state variable
      this.setState({ newDiff: difference });
    }
    // Set the totalAmount, difference, and liquiAmounts in the state
    this.setState({ totalAmount, difference, liquiAmounts });

    // You can call any other methods or perform additional actions here
    // this.presenter.onChangeObject();
  }

  onClickEdit(brTotal, liquiAmount, returnAmount) {
    const returnAmounts = Math.abs(returnAmount);
    console.log("hello", returnAmounts);
    dialog.fire({
      html: (
        <div>
          <div>
            <h5 className=" text-center">
              <i className="bi bi-file-earmark-medical"></i> Are you sure you
              want to proceed with the liquidation?
            </h5>
          </div>
          <div className="text-center m-4">
            <button
              className="btn btn-light  btn-sm me-2"
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm"
              onClick={() => this.reimEdit(returnAmounts)}
              style={{ backgroundColor: "#EBBD2F", color: "white" }}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }
  onClickApproveLiquidation(brTotal, liquiAmount, returnAmount, totalAmount) {
    const returnAmounts = Math.abs(totalAmount - brTotal);
    dialog.fire({
      html: (
        <div>
          <div>
            <h5 className=" text-center">
              <i className="bi bi-file-earmark-medical"></i> Are you sure you
              want to proceed with the liquidation?
            </h5>
          </div>
          <div className="text-center m-4">
            <button
              className="btn btn-light  btn-sm me-2"
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm"
              onClick={() =>
                this.reim(returnAmounts !== brTotal ? returnAmounts : brTotal)
              }
              style={{ backgroundColor: "#EBBD2F", color: "white" }}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }
  reimEdit(reim) {
    const obj = this.state.object;
    console.warn(reim, obj);
    this.presenter.onClickEdit(reim, "Liquidation", obj);
  }

  reim(reim) {
    // dialog.close();
    const obj = this.state.object;
    this.presenter.onClickStatus(reim, "Done", obj);
  }

  onChange(value, field) {
    if (Array.isArray(value)) {
      this.setState({ liquiAmount: value });
    }
    this.presenter.onChange(value, field);
  }

  render() {
    const trys = this.state.liquiAmount
      .map((item) => item.amount)
      .reduce((acc, prev) => acc + prev, 0);
    console.log("trys", trys);
    const { object, totalItems, newDiff, initial } = this.state;
    console.log("ob", object);
    const brTotal = object.totalAmount;
    console.log("brTotal", brTotal);
    const liquiAmount = this.state.liquiAmount
      .map((item) => Number(item.amounts?.replace(/,/g, "")))
      .reduce((acc, prev) => acc + prev, 0);

    const returnAmount = brTotal - liquiAmount;
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    const user = this.getCurrentUser();
    console.log("users", user);

    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    let totalAmount = 0;

    if (object.items_list && object.items_list.length > 0) {
      object.items_list.forEach((item) => {
        if (item.amounts) {
          const amount = parseFloat(item.amounts.replace(/,/g, ""));

          if (!isNaN(amount) && amount > 0) {
            totalAmount += amount;
            console.log("Total Amount:", totalAmount);
          }
        }
      });
    }

    console.log(this.state.initial);
    const role = this.getCurrentRoles();
    console.warn("haha", role[0].name);
    const returnAmounts = Math.abs(returnAmount);
    if (this.state.initial === 0 && this.state.count == false) {
      if (!isNaN(brTotal) && !isNaN(this.state.totalAmount)) {
        const initialDifference = object.totalAmount - this.state.totalAmount;

        if (!isNaN(initialDifference)) {
          this.setState({ initial: initialDifference });
          this.setState({ count: true });
        } else {
          console.error("Initial difference calculation resulted in NaN.");
        }
      } else {
        console.error("brTotal or totalAmount is not a valid number.");
      }
    }

    console.log(this.state.initial);
    return (
      <>
        <NavBar />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1
                className="fw-bold mt-3 text-capitalize"
                onClick={this.onClickBack.bind(this)}
                role="button"
              >
                <i className="bi bi-chevron-left"></i> Add Liquidation
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                {/* <form onSubmit={this.onSubmitForm.bind(this)}> */}
                <div className="row g-3">
                  <div className="mt-4">Items</div>
                  {user.roles.some(
                    (role) =>
                      role.name == "admin" ||
                      role.name == "masterAdmin" ||
                      role.name == "user" ||
                      role.name == "Designer" ||
                      role.name == "Sales" ||
                      role.name == "hrAdmin" ||
                      role.name == "Production"
                  ) ? (
                    <InputFactory2
                      type="Relation"
                      _type="Related"
                      field="items_list"
                      target="items"
                      object={object}
                      onChange={this.onChangeObject.bind(this, object)}
                      required={true}
                      excludeFields={["quantity"]}
                    />
                  ) : (
                    <InputFactory2
                      type="Relation"
                      _type="Related"
                      field="items_list"
                      target="items"
                      object={object}
                      onChange={this.onChange.bind(this)}
                      required={true}
                      excludeFields={["quantity"]}
                      disabled={true}
                    />
                  )}

                  <div className="mt-4">Liquidate</div>
                  <InputFactory2
                    type="Relateds"
                    // _type="Related"
                    field="liqui"
                    target="liquidate"
                    object={object}
                    onChange={this.onChangeObject.bind(this)}
                    //required={true}
                    excludeFields={["chart", "reimbursement"]}
                  />
                  <div className="col">
                    <p className="mb-1 mt-2" style={{ fontSize: "13px" }}>
                      For Return
                    </p>
                    <div
                      className="card col-l-2 pt-2 h-50 ps-2"
                      style={{ backgroundColor: "#e5e3e3" }}
                    >
                      {totalAmount < brTotal ? (
                        <p>
                          {object.items_list.amounts
                            ? Math.abs(totalAmount - brTotal)
                            : this.state.difference !== brTotal
                            ? formatCurrency(this.state.difference)
                            : null}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <p className="mb-1 mt-2" style={{ fontSize: "13px" }}>
                      Reimbursement
                    </p>
                    <div
                      className="card col-l-2 pt-2 h-50 ps-2"
                      style={{ backgroundColor: "#e5e3e3" }}
                    >
                      {totalAmount > brTotal ? (
                        <p>{formatCurrency(totalAmount - brTotal)}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {user.roles.some(
                  (role) =>
                    role.name === "admin" ||
                    role.name === "masterAdmin" ||
                    role.name === "user" ||
                    role.name === "Designer" ||
                    role.name === "Sales" ||
                    role.name === "hrAdmin" ||
                    role.name === "Production"
                ) && (
                  <div className="mt-4 d-flex justify-content-between align-items-center">
                    {user.roles.some(
                      (role) =>
                        role.name === "admin" || role.name === "masterAdmin"
                    ) && (
                      <button
                        type="submit"
                        className="btn btn-success fs-sm me-3"
                        style={{ color: "white" }}
                        onClick={() =>
                          this.onClickApproveLiquidation(
                            brTotal,
                            liquiAmount,
                            returnAmount,
                            totalAmount
                          )
                        }
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>Approve
                      </button>
                    )}
                    {user.roles?.some(
                      (role) =>
                        role.name === "user" ||
                        role.name === "admin" ||
                        role.name === "masterAdmin" ||
                        role.name === "Designer" ||
                        role.name === "Sales" ||
                        role.name === "hrAdmin" ||
                        role.name === "Production"
                    ) && (
                      <button
                        type="submit"
                        className="btn btn-success fs-sm me-3"
                        style={{ color: "white" }}
                        onClick={() =>
                          this.onClickEdit(brTotal, liquiAmount, returnAmount)
                        }
                      >
                        <i className="bi bi-file-earmark-check me-2"></i>
                        Liquidate
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                )}
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LiquidateFormPage);
